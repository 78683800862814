import { Grid, makeStyles } from '@material-ui/core';
import content from '../../../content/team/contact.json';
import RigrafExpanded from '../../../fonts/rigraf/expanded/rigrafExpanded';
import colors from '../../../styles/colors';
import { defaultSectionWidth, largeText, pageTitle, regularText, smallText } from '../../../styles/dimensions';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import MainLinkButton from '../../../elements/mainLinkButton';
import { StaticImage } from 'gatsby-plugin-image';
// @ts-ignore
import backgroundImg from '../../../images/team/texture_contact.jpg';

const Contact = () => {
	const classes = useClasses();

	return (
		<section className={classes.section}>
			<Grid container className={classes.grid}>
				<Grid item md={1}></Grid>
				<Grid item xs={12} md={10}>
					<h2 className={classes.header} data-scroll data-scroll-speed="5">
						{content.meet}
					</h2>
				</Grid>
				<Grid item md={1}></Grid>
			</Grid>
			<p className={classes.paragraph} data-scroll data-scroll-speed="3">
				{content.chitChat}
			</p>
			<div className={classes.buttonContainer} data-scroll data-scroll-speed="3">
				<MainLinkButton to={content.button.path} text={content.button.name} className={classes.button} />
			</div>
			<StaticImage
				src="../../../images/team/lines.svg"
				alt=""
				className={classes.lines}
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
				aria-hidden="true"
			/>
		</section>
	);
};

export default Contact;

const useClasses = makeStyles({
	section: {
		backgroundColor: colors.benefitsBackground,
		backgroundImage: `url(${backgroundImg})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		padding: '8.625em 0',
		paddingBottom: '14.75em',
		position: 'relative',
		zIndex: 1,
		'@media (max-width: 599px)': {
			padding: '7.5em 1em',
		},
	},
	grid: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		position: 'relative',
		zIndex: 1,
	},
	header: {
		textAlign: 'center',
		color: colors.white,
		fontFamily: RigrafExpanded.bold,
		fontSize: pageTitle.fontSize,
		lineHeight: pageTitle.lineHeight,
		marginTop: '1em',
		marginBottom: '0.5em',
		'@media (max-width: 599px)': {
			fontFamily: RigrafExpanded.semiBold,
			fontSize: regularText.fontSize,
			lineHeight: regularText.lineHeight,
			marginBottom: '1em',
		},
	},
	paragraph: {
		textAlign: 'center',
		color: colors.whiteDimmed,
		fontSize: largeText.fontSize,
		lineHeight: largeText.lineHeight,
		whiteSpace: 'pre-wrap',
		margin: '0 1em 2em',
		position: 'relative',
		zIndex: 1,
		'@media (max-width: 599px)': {
			fontFamily: MetroSans.semiBold,
			fontSize: smallText.fontSize,
			lineHeight: smallText.lineHeight,
			marginBottom: '2.75em',
		},
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		zIndex: 1,
	},
	button: {
		marginBottom: '4px',
		marginRight: '4px',
		'&:after': {
			border: `2px solid ${colors.white}`,
		},
		'&:hover': {
			marginBottom: '0px',
			marginRight: '0px',
		},
	},
	lines: {
		// @ts-ignore
		position: 'absolute !important',
		right: 0,
		transform: 'translateY(-39%)',
		zIndex: 0,
		'@media (max-width: 959px)': {
			display: 'none !important',
		},
	},
});
