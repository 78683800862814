import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import colors from '../../../styles/colors';
import content from '../../../content/team/pets.json';
import { defaultSectionWidth, pageTitle, largeText } from '../../../styles/dimensions';
import RigrafExpanded from '../../../fonts/rigraf/expanded/rigrafExpanded';
import { StaticImage } from 'gatsby-plugin-image';

const Pets = () => {
	const classes = useClasses();

	const isMobile = useMediaQuery('(max-width: 599px)');

	return (
		<section className={classes.section}>
			<Grid container spacing={isMobile ? 4 : 7} classes={{ root: classes.gridRoot }}>
				<Grid item xs={12}>
					<h2 className={classes.header} data-scroll data-scroll-speed="2">
						{content.title}
					</h2>
					<div className={classes.headerCircleContainer}>
						<div data-scroll data-scroll-speed="4">
							<StaticImage
								src="../../../images/team/gradient_1.png"
								alt=""
								imgClassName={classes.circle}
								loading="lazy"
								placeholder="blurred"
								aria-hidden="true"
							/>
						</div>
					</div>
				</Grid>

				<Grid item xs={6} sm={4} md={3} lg={2}>
					<div className={classes.petContainer} data-scroll data-scroll-speed="2">
						<StaticImage
							src="../../../images/team/pets/bastet.png"
							alt="Cat - Bastet"
							className={classes.petImage}
							loading="lazy"
							placeholder="blurred"
						/>
						<h5 className={classes.petName}>Bastet</h5>
					</div>
				</Grid>

				<Grid item xs={6} sm={4} md={3} lg={2}>
					<div className={classes.petContainer} data-scroll data-scroll-speed="2">
						<StaticImage
							src="../../../images/team/pets/blacky.png"
							alt="Cat - Blacky"
							className={classes.petImage}
							loading="lazy"
							placeholder="blurred"
						/>
						<h5 className={classes.petName}>Blacky</h5>
					</div>
				</Grid>

				<Grid item xs={6} sm={4} md={3} lg={2}>
					<div className={classes.petContainer} data-scroll data-scroll-speed="2">
						<StaticImage
							src="../../../images/team/pets/brko.png"
							alt="Cat - Brko"
							className={classes.petImage}
							loading="lazy"
							placeholder="blurred"
						/>
						<h5 className={classes.petName}>Brko</h5>
					</div>
				</Grid>

				<Grid item xs={6} sm={4} md={3} lg={2}>
					<div className={classes.petContainer} data-scroll data-scroll-speed="2">
						<StaticImage
							src="../../../images/team/pets/cica.png"
							alt="Cat - Cica"
							className={classes.petImage}
							loading="lazy"
							placeholder="blurred"
						/>
						<h5 className={classes.petName}>Cica</h5>
					</div>
				</Grid>

				<Grid item xs={6} sm={4} md={3} lg={2}>
					<div className={classes.petContainer} data-scroll data-scroll-speed="2">
						<StaticImage
							src="../../../images/team/pets/coka.png"
							alt="Dog - Coka"
							className={classes.petImage}
							loading="lazy"
							placeholder="blurred"
						/>
						<h5 className={classes.petName}>Coka</h5>
					</div>
				</Grid>

				<Grid item xs={6} sm={4} md={3} lg={2}>
					<div className={classes.petContainer} data-scroll data-scroll-speed="2">
						<StaticImage
							src="../../../images/team/pets/crni.png"
							alt="Dog - Crni"
							className={classes.petImage}
							loading="lazy"
							placeholder="blurred"
						/>
						<h5 className={classes.petName}>Crni</h5>
					</div>
				</Grid>

				<Grid item xs={6} sm={4} md={3} lg={2}>
					<div className={classes.petContainer} data-scroll data-scroll-speed="2">
						<StaticImage
							src="../../../images/team/pets/kiki.png"
							alt="Parrot - Kiki"
							className={classes.petImage}
							loading="lazy"
							placeholder="blurred"
						/>
						<h5 className={classes.petName}>Kiki</h5>
					</div>
				</Grid>

				<Grid item xs={6} sm={4} md={3} lg={2}>
					<div className={classes.petContainer} data-scroll data-scroll-speed="2">
						<StaticImage
							src="../../../images/team/pets/lilith.png"
							alt="Cat - Lilith"
							className={classes.petImage}
							loading="lazy"
							placeholder="blurred"
						/>
						<h5 className={classes.petName}>Lilith</h5>
					</div>
				</Grid>

				<Grid item xs={6} sm={4} md={3} lg={2}>
					<div className={classes.petContainer} data-scroll data-scroll-speed="2">
						<StaticImage
							src="../../../images/team/pets/lori.png"
							alt="Dog - Lori"
							className={classes.petImage}
							loading="lazy"
							placeholder="blurred"
						/>
						<h5 className={classes.petName}>Lori</h5>
					</div>
				</Grid>

				<Grid item xs={6} sm={4} md={3} lg={2}>
					<div className={classes.petContainer} data-scroll data-scroll-speed="2">
						<StaticImage
							src="../../../images/team/pets/mima.png"
							alt="Dog - Mima"
							className={classes.petImage}
							loading="lazy"
							placeholder="blurred"
						/>
						<h5 className={classes.petName}>Mima</h5>
					</div>
				</Grid>

				<Grid item xs={6} sm={4} md={3} lg={2}>
					<div className={classes.petContainer} data-scroll data-scroll-speed="2">
						<StaticImage
							src="../../../images/team/pets/minx.png"
							alt="Cat - Minx"
							className={classes.petImage}
							loading="lazy"
							placeholder="blurred"
						/>
						<h5 className={classes.petName}>Minx</h5>
					</div>
				</Grid>

				<Grid item xs={6} sm={4} md={3} lg={2}>
					<div className={classes.petContainer} data-scroll data-scroll-speed="2">
						<StaticImage
							src="../../../images/team/pets/nami.png"
							alt="Dog - Nami"
							className={classes.petImage}
							loading="lazy"
							placeholder="blurred"
						/>
						<h5 className={classes.petName}>Nami</h5>
					</div>
				</Grid>

				<Grid item xs={6} sm={4} md={3} lg={2}>
					<div className={classes.petContainer} data-scroll data-scroll-speed="2">
						<StaticImage
							src="../../../images/team/pets/papilon-neron.png"
							alt="Cats - Papilon and Neron"
							className={classes.petImage}
							loading="lazy"
							placeholder="blurred"
						/>
						<h5 className={classes.petName}>Papilon &amp; Neron</h5>
					</div>
				</Grid>

				<Grid item xs={6} sm={4} md={3} lg={2}>
					<div className={classes.petContainer} data-scroll data-scroll-speed="2">
						<StaticImage
							src="../../../images/team/pets/toto.png"
							alt="Dog - Toto"
							className={classes.petImage}
							loading="lazy"
							placeholder="blurred"
						/>
						<h5 className={classes.petName}>Toto</h5>
					</div>
				</Grid>

				<div className={classes.bellowCircleContainer}>
					<div data-scroll data-scroll-speed="4">
						<StaticImage
							src="../../../images/team/gradient_1.png"
							alt=""
							imgClassName={classes.circle}
							loading="lazy"
							placeholder="blurred"
							aria-hidden="true"
						/>
					</div>
				</div>
			</Grid>
		</section>
	);
};

export default Pets;

const useClasses = makeStyles({
	section: {
		backgroundColor: colors.benefitsBackground,
		paddingBottom: '8.375em',
		position: 'relative',
		'@media (max-width: 599px)': {
			paddingBottom: '5em',
			paddingTop: '0.5em',
		},
	},
	gridRoot: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		paddingLeft: '1em',
		paddingRight: '1em',
		'@media (max-width: 1231px)': {
			maxWidth: '100%',
		},
	},
	header: {
		fontFamily: RigrafExpanded.bold,
		fontSize: pageTitle.fontSize,
		lineHeight: pageTitle.lineHeight,
		margin: 0,
		whiteSpace: 'pre-wrap',
		color: colors.white,
		position: 'relative',
		zIndex: 1,
		'@media (max-width: 599px)': {
			fontFamily: RigrafExpanded.semiBold,
			fontSize: largeText.fontSize,
			lineHeight: largeText.lineHeight,
			marginTop: '2em',
			marginBottom: '1em',
		},
	},
	headerCircleContainer: {
		position: 'absolute',
		transform: 'translate(-70%, -75%)',
		width: '350px',
		height: '350px',
		'@media (max-width: 599px)': {
			transform: 'scale(0.65) translate(-100%, -110%)',
		},
	},
	circle: {
		borderRadius: '50%',
	},
	bellowCircleContainer: {
		position: 'absolute',
		bottom: 0,
		transform: `translate(calc(${defaultSectionWidth} - 40%), 20%)`,
		width: '394px',
		height: '394px',
	},
	petContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		position: 'relative',
		zIndex: 1,
	},
	petImage: {
		maxHeight: '140px',
		maxWidth: '140px',
	},
	petName: {
		marginTop: '0.8em',
		marginBottom: '0.2em',
		color: colors.white,
		position: 'relative',
		zIndex: 1,
		textAlign: 'center',
	},
});
