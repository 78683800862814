import { Grid, makeStyles } from '@material-ui/core';
import { useRef, useEffect, useCallback } from 'react';
import content from '../../../content/team/intro.json';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import RigrafExpanded from '../../../fonts/rigraf/expanded/rigrafExpanded';
import { defaultSectionWidth, largeText, pageSubtitle, regularText } from '../../../styles/dimensions';
import colors from '../../../styles/colors';
import BubbleChart from '../../../elements/bubbleChart';
import { StaticImage } from 'gatsby-plugin-image';
import { splitData } from '../../../helpers/splitData';

const Intro = () => {
	const classes = useClasses();

	const gridItemRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		splitData(0.3, 0.01);

		setTimeout(() => {
			gridItemRef.current.style.opacity = '1';
		}, 1);
	}, []);

	const handleArrowClick = useCallback(() => {
		if (typeof window !== 'undefined') {
			const bubbles = document.querySelector(`.${classes.bubblesContainer} .${classes.bubblesWrapper} svg`);
			const arrow = document.getElementsByClassName(classes.arrow)[0];
			// @ts-ignore
			if (bubbles.style.left !== window.innerWidth - bubbles.getAttribute('width') - 16 + 'px') {
				// @ts-ignore
				bubbles.style.left = window.innerWidth - bubbles.getAttribute('width') - 16 + 'px';
				// @ts-ignore
				arrow.style.transform = 'rotate(180deg)';
			} else {
				// @ts-ignore
				bubbles.style.left = 0 + 'px';
				// @ts-ignore
				arrow.style.transform = 'rotate(0deg)';
			}
		}
	}, []);

	return (
		<section className={classes.section}>
			<Grid container>
				<Grid item sm={1} md={3}></Grid>
				<Grid item xs={12} sm={10} md={6} className={classes.gridItem} ref={gridItemRef}>
					<h1 className={classes.header} data-scroll data-scroll-speed="2" data-splitting>
						{content.header.title}
					</h1>
					<p className={classes.description} data-scroll data-scroll-speed="3" data-splitting>
						{content.header.description}
					</p>
				</Grid>
				<Grid item sm={1} md={3}></Grid>
				<Grid item md={2}></Grid>
				<Grid item xs={12} md={8}>
					<div data-scroll data-scroll-speed="2">
						<h4 className={classes.subheader}>{content.expertise}</h4>
						<StaticImage
							src="../../../images/careers/arrow_icon.png"
							alt="Scroll horizontal"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							className={classes.arrow}
							objectFit="contain"
							onClick={handleArrowClick}
						/>
					</div>

					<div className={classes.bubblesContainer}>
						<div className={classes.meshContainer}>
							<div data-scroll data-scroll-speed="4">
								<StaticImage
									src="../../../images/team/background.png"
									alt=""
									layout="fullWidth"
									loading="lazy"
									placeholder="blurred"
									className={classes.mesh}
									aria-hidden="true"
								/>
								<StaticImage
									src="../../../images/team/background_medium.png"
									alt=""
									layout="fullWidth"
									loading="lazy"
									placeholder="blurred"
									className={classes.meshMedium}
									aria-hidden="true"
								/>
								<StaticImage
									src="../../../images/team/background_small.png"
									alt=""
									layout="fullWidth"
									loading="lazy"
									placeholder="blurred"
									className={classes.meshSmall}
									aria-hidden="true"
								/>
							</div>
						</div>

						<div className={classes.bubblesWrapper} data-scroll data-scroll-speed="2">
							<BubbleChart />
						</div>
					</div>
				</Grid>
				<Grid item md={2}></Grid>
			</Grid>
		</section>
	);
};
export default Intro;
const useClasses = makeStyles({
	section: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		paddingTop: 'calc(7.625em + 2px)',
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
			paddingTop: 'calc(3.75em + 2px)',
		},
		'@media (min-width: 960px)': {
			marginBottom: '10em',
		},
		'@media (min-width: 600px) and (min-height: 1200px)': {
			paddingTop: 'calc(7.625em + 2px + 3vh)',
		},
	},
	header: {
		marginTop: '3em',
		marginBottom: '1em',
		textAlign: 'center',
		fontSize: pageSubtitle.fontSize,
		lineHeight: pageSubtitle.lineHeight,
		fontWeight: 'normal',
		fontFamily: RigrafExpanded.semiBold,
		position: 'relative',
		zIndex: 1,
		'@media (max-width: 599px)': {
			fontSize: largeText.fontSize,
			lineHeight: largeText.lineHeight,
			textAlign: 'left',
			marginTop: '2.3333333333333335em',
			marginBottom: '0.8333333333333334em',
		},
		'& span': {
			fontFamily: 'inherit',
			fontSize: 'inherit',
			lineHeight: 'inherit',
		},
	},
	description: {
		marginTop: 0,
		marginBottom: '10.6em',
		fontFamily: MetroSans.book,
		textAlign: 'center',
		color: colors.textGrey,
		position: 'relative',
		zIndex: 1,
		'@media (max-width: 599px)': {
			fontSize: regularText.fontSize,
			lineHeight: regularText.lineHeight,
			textAlign: 'left',
		},
		'@media (max-width: 959px)': {
			marginBottom: '5.25em',
		},
		'& span': {
			fontFamily: 'inherit',
			fontSize: 'inherit',
			lineHeight: 'inherit',
		},
	},
	subheader: {
		textAlign: 'center',
		marginTop: 0,
		position: 'relative',
		zIndex: 1,
		'@media (max-width: 799px)': {
			textAlign: 'left',
			display: 'inline',
		},
		'@media (min-width: 600px) and (max-width: 799px)': {
			padding: '0 1em',
		},
	},
	meshContainer: {
		// @ts-ignore
		position: 'absolute !important',
		left: '50%',
		zIndex: -1,
		transform: 'translate(-50%, -40%)',
		pointerEvents: 'none',
	},
	mesh: {
		minWidth: '1530px',
		'@media (max-width: 1299px)': {
			display: 'none !important',
		},
	},
	meshMedium: {
		'@media (min-width: 1150px)': {
			minWidth: '1285px',
		},
		'@media (max-width: 1149px)': {
			width: '100vw',
		},
		'@media (min-width: 1300px)': {
			display: 'none',
		},
		'@media (max-width: 1049px)': {
			display: 'none',
		},
	},
	meshSmall: {
		width: '100vw',
		'@media (min-width: 1050px)': {
			display: 'none',
		},
		'@media (max-width: 959px)': {
			display: 'none',
		},
	},
	arrow: {
		float: 'right',
		cursor: 'pointer',
		padding: '0 1em',
		transition: 'transform 0.2s ease-in',
		width: '1.5em',
		userSelect: 'none',
		'@media (min-width: 800px)': {
			display: 'none !important',
		},
	},
	bubblesContainer: {
		display: 'flex',
	},
	bubblesWrapper: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '570px',
		'@media (max-width: 799px)': {
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
		},
		'@media (min-width: 600px) and (max-width: 959px)': {
			padding: '0 1em',
		},
		'@media (max-width: 959px)': {
			marginTop: '2em',
		},
	},
	gridItem: {
		opacity: 0,
	},
});
