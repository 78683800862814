import { makeStyles } from '@material-ui/styles';
import Layout from '../components/layout';
import Contact from '../components/sections/team/contact';
import Employees from '../components/sections/team/employees';
import Intro from '../components/sections/team/intro';
import Pets from '../components/sections/team/pets';
import SEO from '../components/seo';
import { descriptions, titles } from '../consts/seo';
import colors from '../styles/colors';

const Team = () => (
	<Layout>
		<SEO title={titles.team} description={descriptions.team} image="/og_image_team.png" />
		<Intro />
		<div className={useClasses().wrapper}>
			<Employees />
			<Pets />
			<Contact />
		</div>
	</Layout>
);

export default Team;

const useClasses = makeStyles({
	wrapper: {
		backgroundColor: colors.benefitsBackground,
		marginBottom: '-1px',
	},
});
