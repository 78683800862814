import { makeStyles } from '@material-ui/core';
import { useRef, useCallback } from 'react';
import MetroSans from '../fonts/metro-sans/metroSans';
import RigrafExpanded from '../fonts/rigraf/expanded/rigrafExpanded';
import Rigraf from '../fonts/rigraf/regular/rigraf';
import { useScrollContext } from '../hooks/useScrollContext';
import colors from '../styles/colors';
// @ts-ignore
import gradient1 from '../images/team/gradient_1.png';
// @ts-ignore
import gradient2 from '../images/team/gradient_2.png';
// @ts-ignore
import gradient3 from '../images/team/gradient_3.png';
// @ts-ignore
import gradient4 from '../images/team/gradient_4.png';
// @ts-ignore
import cdSVG from '../images/team/cd+brain.png';

const svg = {
	width: 820,
	height: 570,
};

const peopleKnow = 'PEOPLE KNOW';

const BubbleChart = () => {
	const classes = useClasses();

	const svgGRef = useRef<any>(null);

	const scroll = useScrollContext();

	const handleBubbleChartLoad = useCallback(() => {
		if (scroll) {
			scroll.update();
		}
	}, [scroll]);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width={svg.width}
			height={svg.height}
			className={classes.bubbles}
			viewBox={`0 0 ${svg.width} ${svg.height}`}
			onLoad={handleBubbleChartLoad}
			preserveAspectRatio="xMinYMin slice">
			<defs>
				<pattern id="gradient-1" width="1" height="1" x="29%" y="29%" patternTransform="scale(2.4)">
					<image href={gradient1} width="100" height="100" />
				</pattern>
				<pattern id="gradient-2" width="1" height="1" x="17%" y="17%" patternTransform="scale(1.55)">
					<image href={gradient2} width="100" height="100" />
				</pattern>
				<pattern id="gradient-3" width="1" height="1" x="13.5%" y="13%" patternTransform="scale(1.37)">
					<image href={gradient3} width="100" height="100" />
				</pattern>
				<pattern id="gradient-4" width="1" height="1" x="4.5%" y="4.5%" patternTransform="scale(1.1)">
					<image href={gradient4} width="100" height="100" />
				</pattern>
			</defs>

			<g ref={svgGRef}>
				<g id="cd" transform={`translate(${svg.width / 2 - 133.5}, ${svg.height / 2 - 133.5})`}>
					<image href={cdSVG} width="267" height="267" />
				</g>

				<g id="javascript">
					<circle r="117" strokeWidth="0" stroke={colors.backgroundBlack} fill="transparent" />
					<text transform="translate(0, -50)">
						<tspan x="-12.5" dy="0">
							10
						</tspan>
						<tspan x="-39.98" dy="30" className="people-know">
							{peopleKnow}
						</tspan>
						<tspan x="-60.44" dy="28">
							Javascript
						</tspan>
					</text>
				</g>
				<g id="react">
					<circle r="117" strokeWidth="0" stroke={colors.backgroundBlack} fill="transparent" />
					<text transform="translate(0, -50)">
						<tspan x="-12.5" dy="0">
							8
						</tspan>
						<tspan x="-39.98" dy="30" className="people-know">
							{peopleKnow}
						</tspan>
						<tspan x="-32.125" dy="28">
							React
						</tspan>
					</text>
				</g>
				<g id="postgresql">
					<circle r="68" strokeWidth="0" stroke={colors.backgroundBlack} fill="transparent" />
					<text transform="translate(0, -40)">
						<tspan x="-7.5" dy="0">
							5
						</tspan>
						<tspan x="-39.98" dy="25" className="people-know">
							{peopleKnow}
						</tspan>
						<tspan x="-39.665" dy="23">
							Postgresql
						</tspan>
					</text>
				</g>
				<g id="react-native">
					<circle r="76" strokeWidth="0" stroke={colors.backgroundBlack} fill="transparent" />
					<text transform="translate(0, -40)">
						<tspan x="-7" dy="0">
							8
						</tspan>
						<tspan x="-39.98" dy="25" className="people-know">
							{peopleKnow}
						</tspan>
						<tspan x="-47.595" dy="23">
							React Native
						</tspan>
					</text>
				</g>
				<g id="redis">
					<circle r="68" strokeWidth="0" stroke={colors.backgroundBlack} fill="transparent" />
					<text transform="translate(0, -40)">
						<tspan x="-7.5" dy="0">
							5
						</tspan>
						<tspan x="-39.98" dy="25" className="people-know">
							{peopleKnow}
						</tspan>
						<tspan x="-21.515" dy="23">
							Redis
						</tspan>
					</text>
				</g>
				<g id="go">
					<circle r="68" strokeWidth="0" stroke={colors.backgroundBlack} fill="transparent" />
					<text transform="translate(0, -40)">
						<tspan x="-7.5" dy="0">
							5
						</tspan>
						<tspan x="-39.98" dy="25" className="people-know">
							{peopleKnow}
						</tspan>
						<tspan x="-10.845" dy="23">
							Go
						</tspan>
					</text>
				</g>
				<g id="android">
					<circle r="55" strokeWidth="0" stroke={colors.backgroundBlack} fill="transparent" />
					<text transform="translate(0, -35)">
						<tspan x="-5.5" dy="0">
							3
						</tspan>
						<tspan x="-39.98" dy="22" className="people-know">
							{peopleKnow}
						</tspan>
						<tspan x="-27.985" dy="20">
							Android
						</tspan>
					</text>
				</g>
				<g id="ios">
					<circle r="51" strokeWidth="0" stroke={colors.backgroundBlack} fill="transparent" />
					<text transform="translate(0, -35)">
						<tspan x="-4" dy="0">
							3
						</tspan>
						<tspan x="-39.98" dy="22" className="people-know">
							{peopleKnow}
						</tspan>
						<tspan x="-33.96" dy="20">
							iOS/Swift
						</tspan>
					</text>
				</g>
			</g>
		</svg>
	);
};

export default BubbleChart;

const useClasses = makeStyles({
	bubbles: {
		margin: '0 auto',
		position: 'relative',
		left: 0,
		zIndex: 1,
		overflow: 'visible',
		transition: 'left 0.2s ease-in',
		width: svg.width,
		height: svg.height,
		'& g text': {
			fill: colors.white,
			pointerEvents: 'none',
		},
		'& g#javascript text:last-of-type, & g#react text:last-of-type': {
			fontFamily: RigrafExpanded.semiBold,
			fontSize: '20px',
			lineHeight: '32px',
		},
		'& g#postgresql text:last-of-type, & g#redis text:last-of-type, & g#react-native text:last-of-type, & g#go text:last-of-type': {
			fontFamily: Rigraf.semiBold,
			fontSize: '16px',
			lineHeight: '24px',
		},
		'& g#android text:last-of-type, & g#ios text:last-of-type': {
			fontFamily: Rigraf.semiBold,
			fontSize: '14px',
			lineHeight: '20px',
		},
		'& g g:hover circle': {
			transform: 'scale(1.1)',
			transition: 'transform 0.2s linear',
			'@media (max-width: 959px)': {
				transform: 'scale(1)',
			},
		},
		'& g g text tspan.people-know, & g g text tspan:first-of-type': {
			fillOpacity: 0,
			transition: 'fill-opacity 0.2s linear 0s',
			'@media (max-width: 959px)': {
				fillOpacity: 1,
			},
		},
		'& g g:hover text tspan.people-know, & g g:hover text tspan:first-of-type': {
			fillOpacity: 1,
			transition: 'fill-opacity 0.2s linear 0.2s',
		},
		'& g#cd': {
			pointerEvents: 'none',
		},
		'& g text tspan.people-know': {
			fontFamily: MetroSans.light,
			fontSize: '10px',
			lineHeight: '20px',
			letterSpacing: '1.07px',
			fill: colors.white,
			transform: 'translateX(calc(79.94px / 2))',
		},
		'& g#javascript text tspan:first-of-type, & g#react text tspan:first-of-type': {
			fontFamily: RigrafExpanded.semiBold,
			fontSize: '32px',
			lineHeight: '48px',
		},
		'& g#postgresql text tspan:first-of-type, & g#redis text tspan:first-of-type, & g#react-native text tspan:first-of-type, & g#go text tspan:first-of-type': {
			fontFamily: Rigraf.semiBold,
			fontSize: '24px',
			lineHeight: '36px',
		},
		'& g#android text tspan:first-of-type, & g#ios text tspan:first-of-type': {
			fontFamily: Rigraf.semiBold,
			fontSize: '20px',
			lineHeight: '32px',
		},
		'& g g text': {
			transition: 'transform 0.2s linear 0.2s',
		},
		'& g g:hover text': {
			transition: 'transform 0.2s linear 0s',
		},
		'& g#javascript:hover text, & g#react:hover text': {
			transform: 'translate(0, -25px) !important',
		},
		'& g#postgresql:hover text, & g#redis:hover text, & g#react-native:hover text, & g#go:hover text': {
			transform: 'translate(0, -20px) !important',
		},
		'& g#android:hover text, & g#ios:hover text': {
			transform: 'translate(0, -17.5px) !important',
		},
		'& g#javascript text, & g#react text': {
			transform: 'translate(0px, -50px)',
			'@media (max-width: 959px)': {
				transform: 'translate(0, -25px) !important',
			},
		},
		'& g#postgresql text, & g#redis text, & g#react-native text, & g#go text': {
			transform: 'translate(0px, -40px)',
			'@media (max-width: 959px)': {
				transform: 'translate(0, -20px) !important',
			},
		},
		'& g#android text, & g#ios text': {
			transform: 'translate(0px, -35px)',
			'@media (max-width: 959px)': {
				transform: 'translate(0, -17.5px) !important',
			},
		},
		'& g g circle': {
			transition: 'transform 0.2s linear, fill 0s linear 0.2s',
			'@media (max-width: 959px)': {
				fill: colors.bubblePink,
			},
		},
		'& g g': {
			transition: 'transform 0.2s linear !important',
		},

		'& g#javascript:hover': {
			'@media (min-width: 960px)': {
				transform: 'translate(150px, 247px) !important',
				'& ~g#postgresql': {
					transform: 'translate(62px, 70px) !important',
				},
				'& ~g#redis': {
					transform: 'translate(298px, 116px) !important',
				},
				'& ~g#react-native': {
					transform: 'translate(250px, 426px) !important',
				},
			},
		},
		'& g#react:hover': {
			'@media (min-width: 960px)': {
				// transform: 'translate(571px, 298px) !important',
				'& ~g#go': {
					transform: 'translate(516px, 457px) !important',
				},
				'& ~g#android': {
					transform: 'translate(571px, 188px) !important',
				},
				'& ~g#ios': {
					transform: 'translate(674px, 160px) !important',
				},
			},
		},
		'& g#postgresql:hover': {
			'@media (min-width: 960px)': {
				transform: 'translate(66px, 80px) !important',
			},
		},
		'& g#redis:hover': {
			'@media (min-width: 960px)': {
				transform: 'translate(295px, 110px) !important',
			},
		},
		'& g#react-native:hover': {
			'@media (min-width: 960px)': {
				transform: 'translate(246px, 429px) !important',
			},
		},
		'& g#go:hover': {
			'@media (min-width: 960px)': {
				transform: 'translate(524px, 460px) !important',
			},
		},
		'& g#android:hover': {
			'@media (min-width: 960px)': {
				transform: 'translate(580px, 189px) !important',
				'& ~g#ios': {
					transform: 'translate(691px, 172px) !important',
				},
			},
		},
		'& g#ios:hover': {
			'@media (min-width: 960px)': {
				transform: 'translate(684px, 166px) !important',
			},
		},

		// mozzila position fix
		'& g#javascript': {
			transform: 'translate(160px, 247px)',
		},
		'& g#react': {
			transform: 'translate(675px, 340px)',
		},
		'& g#postgresql': {
			transform: 'translate(70px, 85px)',
		},
		'& g#react-native': {
			transform: 'translate(245px, 420px)',
		},
		'& g#redis': {
			transform: 'translate(295px, 120px)',
		},
		'& g#go': {
			transform: 'translate(527px, 450px)',
		},
		'& g#android': {
			transform: 'translate(577px, 198px)',
		},
		'& g#ios': {
			transform: 'translate(679px, 171px)',
		},

		'& g#javascript circle, & g#react circle': {
			fill: 'url(#gradient-1)',
		},
		'& g#react-native circle, & g#go circle': {
			fill: 'url(#gradient-2)',
		},
		'& g#postgresql circle, & g#redis circle, & g#ios circle': {
			fill: 'url(#gradient-3)',
		},
		'& g#android circle': {
			fill: 'url(#gradient-4)',
		},
	},
});
